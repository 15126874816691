<template>
  <el-form
    ref="form"
    :rules="rules"
    :model="formData"
  >
    <el-row
      class="row-bg"
      :gutter="30"
      justify="space-around">
      <v-row-col>
        <el-row
          class="row-bg"
          :gutter="20"
          justify="space-around">
          <v-row-col
            same
            :same-number="24">
            <div class="form-item ">
              <span>Registration Mark</span>
              <el-form-item prop="registration_mark">
                <v-input
                  v-model="formData.registration_mark"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            same
            :same-number="24">
            <div class="form-item">
              <span>Year of Manufacture</span>
              <el-form-item prop="year_of_manufacture">
                <v-input
                  v-model="formData.year_of_manufacture"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            same
            :same-number="24">
            <div class="form-item ">
              <span>Car Make</span>
              <el-form-item prop="car_make_id">
                <v-select
                  v-model="formData.car_make_id"
                  :list="carMakeList"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            same
            :same-number="24">
            <div class="form-item ">
              <span>Car Model</span>
              <el-form-item prop="car_model">
                <v-input
                  v-model="formData.car_model"

                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            same
            :same-number="24">
            <div class="form-item ">
              <span>Body Type</span>
              <el-form-item prop="body_type_id">
                <v-select
                  v-model="formData.body_type_id"
                  :list="carModelList"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            same
            :same-number="24">
            <div class="form-item">
              <span>Vehicle Cylinder Capacity</span>
              <el-form-item prop="vehicle_cylinder_capacity">
                <v-input
                  v-model="formData.vehicle_cylinder_capacity"
                  append="cc"
                />
              </el-form-item>
            </div>
          </v-row-col>
        </el-row>
      </v-row-col>
      <v-row-col>
        <el-row
          class="row-bg"
          :gutter="20"
          justify="space-around">
          <v-row-col
            v-if="carType==1002"
            same
            :same-number="24">
            <div class="form-item ">
              <span>Permitted Gross Vehicle Weight</span>
              <el-form-item prop="permitted_gross_vehicle_weight">
                <v-input
                  v-model="formData.permitted_gross_vehicle_weight"
                  append="tonnes"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            same
            :same-number="24">
            <div class="form-item ">
              <span>Seating Capacity</span>
              <el-form-item prop="searing_capacity">
                <v-input
                  v-model="formData.searing_capacity"
                />
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col
            same
            :same-number="24">
            <div class="form-item ">
              <span>Chassis Number</span>
              <el-form-item prop="chassis_number">
                <v-input v-model="formData.chassis_number"/>
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col
            same
            :same-number="24">
            <div class="form-item ">
              <span>Engine Number</span>
              <el-form-item prop="engine_number">
                <v-input v-model="formData.engine_number"/>
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col
            same
            :same-number="24">
            <div class="form-item ">
              <span>NCD</span>
              <el-form-item prop="ncd">
                <v-select
                  v-model="formData.ncd"
                  :list="ncdList"
                  @change="changeNcd"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <div v-if="ncd>0">
            <v-row-col
              same
              :same-number="24">
              <div class="form-item ">
                <span>Previous Policy Number</span>
                <el-form-item prop="policy_number">
                  <v-input v-model="formData.policy_number"></v-input>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              same
              :same-number="24">
              <div class="form-item ">
                <span>Previous Insurance Company</span>
                <el-form-item prop="ncd">
                  <v-input v-model="formData.previous_insurance_company"></v-input>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              same
              :same-number="24">
              <div class="form-item ">
                <span>Registration Number</span>
                <el-form-item prop="ncd">
                  <v-input v-model="formData.registration_number"></v-input>
                </el-form-item>
              </div>
            </v-row-col>
          </div>

        </el-row>
      </v-row-col>

    </el-row>


  </el-form>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {mapActions, mapState} from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'vehicleDetailForm',

  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    carType:{
      type: Number,
      default: 1001
    },
    // ncd:{
    //   type:Number,
    //   default:0
    // }
  },
  data(){
    return{
      formData:{
        registration_mark: '',
        searing_capacity: '',
        car_make_id: '',
        chassis_number: '',
        car_model: '',
        body_type_id: '',//i
        engine_number: '',
        ncdShow: '',//i,
        ncd:'',
        vehicle_cylinder_capacity: '',//i
        year_of_manufacture: '',
        permitted_gross_vehicle_weight:'',
        policy_number:'',
        previous_insurance_company:'',
        registration_number:''
      }
    }
  },
  computed:{
    ...mapState('quotation', ['quoteInformationForm','ncd','ncdList']),
    isCreateRenew(){
      let {isDraft,isRenew} = this.$route.query||{}
      return isDraft=='true'&&isRenew=='true'
    },
    carMakeList(){
      let carList = []
      this.globalOptions.car_make.some(item=>{
        if(item.class_id==this.carType)
          carList = item.option
      })
      return carList
    },
    carModelList(){
      let carList = []
      this.globalOptions.body_type.some(item=>{
        if(item.class_id==this.carType)
          carList = item.option
      })
      return carList
    },
    isRenew(){
      return   this.form.policy_type ==201&&!this.$route.query.isRenew == 'true'
    },
    isQueryRenew(){
      return this.$route.query.isRenew =='true'
    }
  },
  watch:{
    form: {
      immediate: true,
      handler(val) {
        let {vehicle_detail={},company_id} =val||{}
        Object.keys(this.formData).some(key => {
          if (key in vehicle_detail) {
            this.formData[key] = vehicle_detail[key]
          }
        })
        // if(!this.isQueryRenew){
        this.formData.policy_number = val.policy_no
        this.formData.previous_insurance_company = this.getOptionName('globalOptions.company',company_id)
        this.formData.registration_number =vehicle_detail.registration_number||''
        if(!this.isQueryRenew) {
          this.formData.previous_insurance_company = vehicle_detail.previous_insurance_company
          this.formData.policy_number = vehicle_detail.policy_number
        }
        // }
        // if(this.isQueryRenew){
        //   this.formData.policy_number = ''
        //   this.formData.ncd = ''
        //   this.formData.previous_insurance_company = ''
        //   this.formData.registration_number =''
        // }
      }
    },
    ncd:{
      immediate:true,
      handler(val){
        this.formData.ncd = val
      }
    }
  },
  mounted() {
    // let {company_id} =JSON.parse(JSON.stringify(this.quoteInformationForm))||{}

  },
  methods:{
    ...mapActions('quotation', ['setNcd']),

    async submitForm(formName) {
      await new Promise((resolve, reject) => {
        this.$refs['form'].validate(valid => {
          if(valid) {
            this.submitData(formName)
            resolve(valid)
          } else {
            reject(valid)
          }
        })
      })
    },
    submitData(formName){
      let data ={
        vehicle_detail:{...this.formData}
      }
      this.$emit('getFormData', formName, data)

    },
    changeNcd(){
      this.setNcd(this.formData.ncd)
    }
  }
}
</script>

<style scoped>

</style>
