<template>
  <el-form
    ref="form"
    :model="formData"
    :rules="rules"
  >
    <el-row
      class="row-bg"
      :gutter="30"
      justify="space-around">
      <v-row-col v-if="carType!==1003&&cover_type!==1||carType===1004">
        <el-row
          class="row-bg"
          :gutter="30"
          justify="space-around">
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Own Damage</span>
              <el-form-item prop="own_damage">
                <v-input
                  v-model='formData.own_damage'
                />
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Parking</span>
              <el-form-item prop="parking">
                <v-input
                  v-model.number='formData.parking'
                  money
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Unnamed Driver</span>
              <el-form-item prop="unnamed_driver">
                <v-input
                  v-model.number='formData.unnamed_driver'
                  money
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Young Driver</span>
              <el-form-item prop="young_driver">
                <v-input
                  v-model.number='formData.young_driver'
                  money
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Inexperienced Driver</span>
              <el-form-item prop="inexperienced_driver">
                <v-input
                  v-model.number='formData.inexperienced_driver'
                  money
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Theft</span>
              <el-form-item prop="theft">
                <v-input
                  v-model='formData.theft'
                />
              </el-form-item>
            </div>
          </v-row-col>
        </el-row>
      </v-row-col>
      <v-row-col>
        <el-row
          :gutter="30"
          align="middle"
        >
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>TPPD</span>
              <el-form-item prop="tppd">
                <v-input
                  v-model.number='formData.tppd'
                  money
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>TPPD Young Driver</span>
              <el-form-item prop="tppd_young_driver">
                <v-input
                  v-model.number='formData.tppd_young_driver'
                  money
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>TPPD Inexperienced Driver</span>
              <el-form-item prop="tppd_inexperienced_driver">
                <v-input
                  v-model.number='formData.tppd_inexperienced_driver'
                  money
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>TPPD Unnamed Driver</span>
              <el-form-item prop="tppd_unnamed_driver">
                <v-input
                  v-model.number='formData.tppd_unnamed_driver'
                  money
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>
        </el-row>
      </v-row-col>
    </el-row>
  </el-form>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
export default {
  name: 'excess',
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    carType:{
      type: Number,
      default: 0
    },
    coverType:{
      type: Number,
      default: 1
    }
  },
  data(){
    return{
      cover_type:1,
      formData:{
        own_damage: '',
        parking: 0,
        unnamed_driver: 0,
        young_driver: 0,
        inexperienced_driver: 0,
        theft: '',
        tppd: 0,
        tppd_young_driver: 0,
        tppd_inexperienced_driver: 0,
        tppd_unnamed_driver: 0
      }
    }
  },
  watch:{
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        Object.keys(this.formData).some(key => {
          if (key in val) {
            this.formData[key] = val[key]
          }
        })
      }
    },
    coverType:{
      deep: true,
      immediate: true,
      handler(val) {
        this.cover_type =val
      }
    }
  },
  methods:{
    async submitForm(formName) {
      await new Promise((resolve, reject) => {
        this.$refs['form'].validate(valid => {
          if(valid) {
            this.submitData(formName)
            resolve(valid)
          } else {
            reject(valid)
          }
        })
      })
    },
    submitData(formName){
      let data = {
        excess:{...this.formData}
      }
      this.$emit('getFormData', formName, data)

    }
  }
}
</script>

<style scoped>

</style>
