<template>
  <section class="dirver-form">
    <div class="flex">
      <v-title :title="titles"></v-title>
      <div>
        <v-button
          class="button-theme"
          @click="handlerAddDriver">
          Add {{showMotor?'Driver':'Traveler'}}
        </v-button>
      </div>
    </div>

    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <el-row
        v-for="(item,index) in formData.driver"
        :key="index"
        class="row-bg"
        :gutter="20"
        justify="space-around"
      >
        <div class="flex">
          <span>{{showMotor?'Driver':'Traveler'}}{{ index + 1 }}</span>
          <span
            class="hand"
            @click="handlerDeleteDriver(index)">Delete</span>
        </div>
        <v-row-col>
          <div class="form-item">
            <span>Name</span>
            <el-form-item prop="name">
              <v-input
                v-model="item.name"
                :disabled="isDisable&&index==0"
              />
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col v-if="showMotor">
          <div class="form-item">
            <span>Occupation</span>
            <el-form-item prop="occ">
              <v-input-select
                v-model="item.occ"
                :list="globalOptions.occ "
                :valueKey="'name'"
                :disabled="isDisable&&index==0"
              ></v-input-select>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col>
          <div class="form-item ">
            <span>Date of Birth</span>
            <el-form-item prop="birth">
              <v-date-picker
                v-model="item.birth"
                :hasDeault="true"
                :disabled="isDisable&&index==0"
              ></v-date-picker>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col v-if="showMotor">
          <div class="form-item">
            <span>Driving Experience</span>
            <el-form-item prop="driver_experience_id">
              <v-select
                v-model="item.driver_experience_id"
                :list="globalOptions.driving_experience"
              ></v-select>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col>
          <div class="form-item">
            <!--                  <span>HKID/Passport Number</span>-->
            <el-form-item
              :ref="'driver.' + index + '.id_type'"
              :prop="'driver.' + index + '.id_type'"
              class="form-none-margin-bottom">
              <v-radio-group
                v-model="item.id_type"
                :disabled="isDisable&&index==0"
                :list="personalIdTypOptions"/>
            </el-form-item>
            <el-form-item
              v-if="item.id_type==1||item.id_type==''"
              :ref="'driver.' + index + '.id_no'"
              :prop="'driver.' + index + '.id_no'"
              :rules="rules.id_no"
            >
              <v-input
                v-model="item.id_no"
                class="mg-t-5"
                :disabled="isDisable&&index==0"
                :isUpper="true"
              />
            </el-form-item>
            <el-form-item
              v-if="item.id_type==2"
              :ref="'driver.' + index + '.pp_no'"
              :prop="'driver.' + index + '.pp_no'"
            >
              <v-input
                v-model="item.id_no"
                :disabled="policyOneDriver&&index==0"
                class="mg-t-5"/>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col>
          <div class="form-item ">
            <span>Relationship with Proposer</span>
            <el-form-item prop="relationship_id">
              <v-select
                v-model="item.relationship_id"
                :disabled="isDisable&&index==0"
                :list="isDisable&&index!=0?relationshipList:relationshipOptions"
              />
            </el-form-item>
          </div>
        </v-row-col>
      </el-row>
    </el-form>
  </section>
</template>

<script>
import options from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import IDCheck from '@/utils/idcheck'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'driverDetailForm',
  mixins: [options,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    carType: {
      type: Number,
      default: 0
    },
    policyOneDriver:{
      type: Boolean,
      default: false
    },
    clientType:{
      type: Number,
      default: 1
    }
  },
  data() {
    const checkIdNo = (rule, value, callback) => {
      const index = rule.field.split('.')[1]
      const { id_no,id_type } = this.formData.driver[index]
      if( value&&!IDCheck(value)) {
        callback(new Error('請輸入正確的身份證號碼'))
      }
      // else {
      //   if(!id_no && !value) {
      //     callback(new Error('請輸入正確的身份证号码'))
      //   }
      // }
      callback()
    }
    const checkPPNo = (rule, value, callback) => {
      const { pp_no,policy_holder_id_type } = this.formData
      if(policy_holder_id_type===2&& !pp_no && !value) {
        callback(new Error('請輸入Passport'))
      }
      callback()
    }

    return {
      oldFormDataLock:-1,
      id_type: '',
      driver: {
        name: '',
        occ_id: '',//i
        occ: '',//i
        birth: '',
        driver_experience_id: '',//1:less than 1 years /2: 1-2  years /3: 2-5 years/4: more than 5 years
        id_type: 1,//1-hkid 2-passport,
        id_no: '',
        relationship_id: '',//1 Parent /2 Child /3 Spouse / 4 Relative /5 Friend /6 Employee /7 Employer
      },
      formData: {
        driver: [{
          ...this.driver
        }]
      },
      rules: {
        id_no: [
          { validator: checkIdNo, trigger: ['blur'] }
        ]
      },
    }
  },
  computed:{
    ...mapState('quotation', ['productID']),
    showMotor(){
      return this.productID==10
    },
    titles(){
      return this.productID==10?'Driver Details':'Insured Detail'
    },
    relationshipList(){
      let arr = this.relationshipOptions
      arr = arr.filter(item=>{
        return item.id!==8
      })
      return arr
    },
    isDisable(){
      return this.clientType==1&&this.policyOneDriver
    }
  },
  watch:{
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        if(this.formDataLock!==this.oldFormDataLock){
          this.oldFormDataLock = this.formDataLock
          let {drivers=[{...this.driver}]} =val
          if(drivers.length>0)
            this.formData.driver = drivers
          this.driver  = JSON.stringify(this.driver)

        }
      }
    },
    policyOneDriver: {
      immediate: true,
      handler(status) {
      }
    }
  },
  methods: {
    handlerChange(){
      console.log('this---currentTarget',event.currentTarget)
      console.log('this---target',event.target)
    },
    submitForm(formName) {
      this.$refs['form'].validate(valid => {
        if(valid) {
          this.$emit('getFormData', formName, this.formData)
        } else {
        }
      })
    },
    //添加司机
    handlerAddDriver() {
      let driver = JSON.parse(this.driver)
      if( this.formData.driver.length>=4)
        return
      this.formData.driver.push(driver)
    },
    handlerDeleteDriver(index) {
      if (this.formData.driver.length <= 1) return
      this.formData.driver.splice(index, 1)
    },
    // 關聯投保人資料
    handlerPolicyDriverInfo(data) {
      const {
        policy_holder_name, policy_holder_mobile, policy_holder_email,
        policy_holder_id_no, policy_holder_id_type, policy_holder_occ,
        policy_holder_birth,policy_holder_is_driver
      } = data
      // console.log('policy_holder_id_no',data)
      this.policy_holder_is_driver=policy_holder_is_driver
      if(policy_holder_is_driver)
      {
        Object.assign(this.formData.driver[0], {
          name: policy_holder_name,
          // occ_id: policy_holder_occ_id,//i
          occ: policy_holder_occ,//i
          birth: policy_holder_birth,
          id_type: policy_holder_id_type,//1-hkid 2-passport,
          id_no: policy_holder_id_no,
          relationship_id:8
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dirver-form {
  .row-bg {
    background: #fff;
    border-radius: 8px;
    padding: 10px 0;
    margin-bottom: 10px;
    margin-right: 0!important;
    margin-left: 0!important;
    .flex {
      padding: 10px;

      span {
        color: #000;
        font-size: 16px;
        font-weight: 500;

        &:nth-child(2) {
          font-size: 14px;
          color: #076081;
        }
      }
    }
  }

  .flex {
    justify-content: space-between;
    align-items: center;
  }
}
</style>
